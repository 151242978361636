.loadingContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingText {
  font-size: 1.5vw;
}
