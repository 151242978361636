.rowContainer {
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: 12vh 1fr;
  scroll-snap-align: start;
}

.dateContainer {
  grid-column: 1 / 3;
  grid-row: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dateText {
  font-size: 1.75vw;
  font-weight: bold;
  border-bottom: 1px solid black;
  padding: 0.5vw;
}

.skynoteRow {
  grid-column: 1 / 3;
  grid-row: 2;
  grid-template-columns: 50vw 50vw;
  display: grid;
}

.leftSkynoteContainer {
  grid-column: 1;
  grid-row: 1;
}

.rightSkynoteContainer {
  grid-column: 2;
  grid-row: 1;
}
