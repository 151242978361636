.boardContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.boardContainer.won {
  background-color: rgb(57, 134, 87);
}

.hidden {
  background-color: rgb(23, 23, 23);
  border: 1px dashed rgb(47, 47, 47);
}

.hidden.lost {
  background-color: rgb(211, 80, 80);
  border: 1px dashed rgb(255, 255, 255);
}

.revealedBomb {
  background-color: rgb(211, 80, 80);
  color: white;
}

.revealedBomb.lost {
  background-color: rgb(23, 23, 23);
  border: 1px dashed rgb(255, 255, 255);
}

.revealedSquare {
  background-color: white;
  border: 1px dashed rgb(255, 255, 255);
}

.revealedSquare.won {
  color: rgb(225, 225, 225);
}

.flagged {
  background-color: rgb(211, 80, 80);
  color: white;
  border: 1px dashed rgb(255, 255, 255);
}

.flagged.lost {
  background-color: rgb(131, 131, 131);
  border: 1px dashed rgb(255, 255, 255);
}

.flagged.won {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: 1px dashed white;
}

.board {
  text-align: center;
  border-collapse: separate;
  border-spacing: 0;
}

.board.won {
  border: 10px solid rgb(57, 134, 87);
}

.board.lost {
  border: 10px solid rgb(211, 80, 80);
}

table,
td,
th {
  gap: 0;
}

table {
  border: 10px solid rgb(23, 23, 23);
}

.board tr {
  height: 2vw;
}

.board th {
  width: 2vw;
}

.selected {
  outline: 0.3vw solid rgb(112, 112, 112);
}

.gameContainer {
  font-family: "Roboto Mono";
}

.boomTextBack {
  font-size: 5vw;
  position: absolute;
  font-style: italic;
  font-weight: bold;
  color: white;
  margin-top: -5vw;
}

.boomTextFront {
  margin-top: calc(-5vw + 0.4vw);
  margin-left: 0.4vw;
  color: black;
  font-size: 5vw;
  position: absolute;
  font-style: italic;
  font-weight: bold;
}

.bombsRemainingText {
  margin-top: 1vw;
  margin-bottom: 2vw;
  font-weight: bold;
}

.digitInput {
  font-family: "Roboto Mono";
  text-align: center;
  font-size: 1.5vw;
  width: 2vw;
  margin-left: 0.25vw;
  margin-right: 0.25vw;
}

.digitInput.extraSpace {
  margin-right: 1.5vw;
}

input {
  border: none;
}

.redInput {
  outline: 2px solid rgb(211, 80, 80);
  color: rgb(211, 80, 80);
}

.redInput:focus {
  outline: 4px solid rgb(211, 80, 80);
  background-color: rgba(211, 80, 80, 0.15);
}

.greenInput {
  outline: 2px solid rgb(57, 134, 87);
  color: rgb(57, 134, 87);
}

.greenInput:focus {
  outline: 4px solid rgb(57, 134, 87);
  background-color: rgb(57, 134, 87, 0.15);
}

.blueInput {
  outline: 2px solid rgb(48, 85, 179);
  color: rgb(48, 85, 179);
}

.blueInput:focus {
  outline: 4px solid rgb(48, 85, 179);
  background-color: rgb(48, 85, 179, 0.15);
}

.colorPreview {
  width: 3vw;
  height: 3vw;
  display: block;
  border-top-left-radius: 0.5vw;
  border-bottom-left-radius: 0.5vw;
}

.checkButton {
  font-family: "Roboto Mono";
  margin-left: 0.3vw;
  width: 7vw;
  font-size: 1.25vw;
  font-weight: bold;
  border: none;
  background-color: rgb(223, 223, 223);
  cursor: pointer;
  border-top-right-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
  transition-property: background-color;
  transition-duration: 1s;
}

.checkButton:hover {
  background-color: rgb(200, 200, 200);
}

.colorPreviewAndCheckButton {
  margin-top: 2vw;
  display: flex;
  flex-direction: row;
}

.timer {
  font-size: 2vw;
  margin-bottom: 1vw;
  font-weight: bold;
  background-color: rgb(23, 23, 23);
  color: white;
  padding-top: 0.25vw;
  padding-bottom: 0.25vw;
  width: 8vw;
  text-align: center;
  border-radius: 0.3vw;
}

.timer.red {
  color: rgb(239, 96, 49);
}

.timer.boom {
  color: white;
  background-color: rgb(211, 80, 80);
}

.timer.green {
  color: white;
  background-color: rgb(57, 134, 87);
}

.textScollerContainer {
  font-family: "Roboto Mono";
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vw;
  font-size: 1.5vw;
}

.scrollTextParagraph {
  width: 50%;
  margin-bottom: 2vw;
}

.scrollTextEnterPrompt {
  font-weight: bold;
}

.postMinesweeperLetter {
  position: absolute;
  top: 2vw;
  right: 2vw;
  background-color: white;
  border: none;
  width: 3vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: background-color, color;
  transition-duration: 0.5s;
  cursor: pointer;
}

.finalLetter {
  margin-top: 4vw;
  background-color: rgb(221, 154, 4);
  color: white;
}

.postMinesweeperLetter:hover {
  background-color: rgb(236, 236, 236);
}

.postMinesweeperLetter.finalLetter:hover {
  background-color: rgb(249, 196, 80);
}

.emailSymbol {
  font-size: 1.8vw;
}

.finalMessageContainer {
  padding-left: 5vw;
  font-size: 1.3vw;
  font-family: "Roboto Mono";
  padding-right: 40vw;
  padding-top: 3vw;
  min-height: 100vh;
}

a {
  color: white;
  text-decoration: none;
}

.playAudioButton {
  font-family: "Roboto Mono";
  font-size: 1.3vw;
  width: 15vw;
  text-align: center;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border: none;
  background-color: rgb(221, 154, 4);
  color: white;
  font-weight: bold;
  margin-top: 3vw;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.5s;
  border-top-right-radius: 1vw;
  border-bottom-right-radius: 1vw;
}

.playAudioButton:hover {
  background-color: rgb(239, 177, 43);
}

.playAudioButton.listening {
  background-color: rgb(182, 182, 182);
  cursor: initial;
}

.lyrics {
  font-size: 1.3vw;
  font-family: "Roboto Mono";
  padding-top: 3vw;
  padding-bottom: 10vw;
}

body {
  height: unset;
  overflow: initial;
}
