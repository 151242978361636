.skynoteBody {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.skynoteImage {
  width: 25vw;
  height: 25vw;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.skynoteImage.noImage {
  box-shadow: rgba(177, 177, 177, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.skynoteImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.skynoteContainer {
  display: grid;
  grid-template-rows: 50vh 38vh;
  height: 100%;
  overflow: hidden;
}

.skynoteTextContainer {
  padding-top: 2vw;
  padding-bottom: 2vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}

.skynoteText {
  width: 25vw;
  text-align: justify;
  font-size: 1.65vw;
  font-family: "Reenie Beanie";
}

.byLine {
  font-style: italic;
  text-align: right;
  margin-top: 1vw;
}

.noImageText {
  color: gray;
}
